
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BasicInfo from './components/BasicInfo.vue';
import UploadMaterial from './components/UploadMaterial.vue';
import XdocProjectPlan from './components/XdocProjectPlan.vue';
import XdocProjectPlanForWord from './components/XdocProjectPlanForWord.vue';
import XdocProjectPlanForFbd from './components/XdocProjectPlanForFbd.vue';
import Template from './components/Template.vue';
import OrderCheckMessage from './components/OrderCheckMessage.vue';
import { IOrderForm, IXdocPlanning } from '@/types/placeOrderTypes';
import { getOrder, editOrder, submitOrder, getOrgConfig, setOrgConfig } from '@/api/placeOrder';
// @ts-ignore
import _ from 'lodash';
import { Route } from 'vue-router';
import { IFormMap } from '@/store/modules/formMap';
import { IServiceMap } from '@/business/BusinessFormData';
import { rerunWord2FbdOrder, rerunOrderStatus } from '@/api/Allservices';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  components: {
    BasicInfo,
    UploadMaterial,
    XdocProjectPlan,
    XdocProjectPlanForWord,
    XdocProjectPlanForFbd,
    Template,
    OrderCheckMessage,
  },
})
export default class PlaceOrder extends Vue {
  @Action('setOrderId') setOrderId: any;
  @Getter('order_id') order_id: any;
  @Getter('customerAppKey') customerAppKey: any;
  @Getter('user') user: any;
  @Getter('organization') organization: any;
  @Getter('formMap')
  formMap!: IFormMap;

  defaultForm = {
    app_key: '',
    deadline: this.getDeadLineTime(),
    tag: { priority_tag: -1 },
    service_content: 0,
    customer_id: '',
    customer_name: '',
    status: 0,
    grade_uid: '',
    subject: '',
    stage: '',
    alias: '',
    book_name: '',
    source: 1,
    config: {
      is_convert_image: false,
      is_convert_latex_to_image: false,
      is_convert_table_to_image: false,
    },
    material: {
      type: 0,
      oss_fbd_zips: [],
      oss_zip_files: [],
      oss_image_zips: [],
      oss_word_files: [],
      oss_pdf_files: [],
      oss_ppt_files: [],
      oss_images: [],
      content_desc: '',
      courseware_sample_files: [],
      courseware_sample_desc: ''
    },
    xdoc_planning: []
  }
  form: IOrderForm = _.cloneDeep(this.defaultForm);
  loading = false;
  repeatName = false;
  regBookName = false;
  regAliasName = false;
  titleList: string[] = [];

  // 内容数字化pdf生成物筛选框
  digit_delivery = 3;

  needAttachment = false;
  needSeewo = false;
  get ifAttachmentShow() {
    if ((this.form.service_content === 5 && this.form.material?.type !== 1) || this.form.service_content === 0) {
      return true;
    }
    return false;
  }

  get isFree() {
    // 是 1 的话是免费用户
    return this.user?.orgs[0]?.isFree === 1;
  }

  @Watch('$route.query.id', { immediate: true, deep: true })
  getId() {
    this.setOrderId(this.$route.query.id);
    if (!this.$route.query.id) {
      this.form = _.cloneDeep(this.defaultForm);
    }
  }

  @Watch('form.material', { deep: true })
  createXdocPlanning() {
    // 除了手动创建 xdoc 计划的 fbd相关服务外，其余 xdoc 计划均为自动创建
    // 课件制作
    if (this.type === 'screenShotCourseWare' && this.form.material.type === 3 && this.form.material.oss_pdf_files.length) {
      this.form.xdoc_planning = []; // 暂未接通
    }
    // 格式转换
    // 题库 处理 word 和 pdf
    if (this.type === 'questionBank' || this.type === 'pdfToDocx') {
      if (this.form.material.type === 1 && this.form.material.oss_word_files?.length && this.form.service_content !== 5) {
        this.form.xdoc_planning = [{
          name: this.form.alias,
          config: {
            delivery: 3,
          },
          origin_files: [
            {
              name: this.form.material.oss_word_files[0].name,
              type: 1
            }
          ],
          is_del: false,
        }];
      } else if (this.form.material.type === 3 && this.form.material.oss_pdf_files.length) {
        this.form.xdoc_planning = [{
          name: this.form.alias,
          config: {
            delivery: this.form.service_content === 5 ? 3 : 1,
          },
          origin_files: this.form.material.oss_pdf_files.map(item => {
            return {
              name: item.name,
              type: item.type === 'answer' ? 0 : 1,
            };
          }),
          is_del: false,
        }];
      }
    }
  }
  // 防止刷新之后丢失 parmas参数
  beforeRouteEnter(to: Route, from: Route, next: () => void) {
    let { serviceType: type, name } = to.params;
    if (!type || !name) {
      type = window.sessionStorage.getItem('service_type')!;
      name = window.sessionStorage.getItem('service_name')!;
    }
    to.params.serviceType = type;
    to.params.name = name;
    next();
  }
  type = ''
  name = '服务下单'
  formInfo: null | IServiceMap = null;

  reEdit = false;
  created() {
    if (this.$route.query.reEdit) {
      this.reEdit = true;
    } else {
      this.reEdit = false;
    }
    if (this.$route.query && this.$route.query.service_type && this.$route.query.service_name) {
      window.sessionStorage.setItem('service_type', this.$route.query.service_type as string);
      window.sessionStorage.setItem('service_name', this.$route.query.service_name as string);
    };
  }

  async mounted() {
    const { serviceType, name } = this.$route.params;
    this.type = serviceType;
    this.name = name;
    this.formInfo = this.formMap.formMap![serviceType];
    if (this.formInfo.category !== 'courseware') {
      this.defaultForm.alias = `${this.organization.orgName}-${this.name}`;
    }
    this.defaultForm.app_key = this.customerAppKey.appKey;
    this.defaultForm.customer_id = this.organization.id + '';
    this.defaultForm.customer_name = this.organization.orgName;
    this.defaultForm.service_content = this.formInfo.formData.service_content;
    this.defaultForm.material.type = this.formInfo?.formData.material?.type || 0;
    await this.init();
    // if (this.type !== 'editableCourseWare') {
    //   this.handleDefaultXdocPlanning();
    // }

    // trick: 防止别的组件刷新之后replace页面
    (this as any).$updateQuery({
      fbd_config_visible: '0',
    });
  }
  async init() {
    if (this.order_id) {
      this.form = await getOrder({
        ticket_id: this.order_id
      });
      this.loading = true;
    } else {
      this.form = _.cloneDeep(this.defaultForm);
      this.loading = true;
    }
    // 获取配置
    const orgConfig = await getOrgConfig({ customer_id: this.organization.id });
    const fbdConfig = orgConfig.fbd_align_config;
    if (fbdConfig) {
      // 去重
      const titleList = [...(this.form.xdoc_planning?.[0]?.config?.stu2tea_title || []), ...fbdConfig.titleList].filter((item, index, arr) => arr.indexOf(item) === index);
      this.titleList = titleList;
    }
  }

  // 时间这里要求返回int 目前是obj
  add0(m: number) {
    return m < 10 ? '0' + m : m;
  }

  getDeadLineTime() {
    const timestamp = new Date().getTime() + 14 * 24 * 3600 * 1000;
    const time = new Date(timestamp);
    const y = time.getFullYear();
    const m = time.getMonth() + 1;
    const d = time.getDate();
    return y + '-' + this.add0(m) + '-' + this.add0(d);
  }

  creatCheckForm(form: IOrderForm) {
    if (!form.book_name && this.formInfo!.category === 'courseware' && this.form?.service_content !== 25) return this.$message.error('请输入图书名称');
    if (!form.alias && this.form?.service_content !== 25) return this.$message.error('请输入工单别名');
    if (!form.subject) return this.$message.error('请选择学科');
    if (!form.stage) return this.$message.error('请选择学段');
    if (!form.customer_id) return this.$message.error('请选择客户');
    if (!form.app_key) return this.$message.error('请选择AppKey');
    if (!form.deadline) return this.$message.error('请输入承诺完成时间');
    if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(form.book_name)) {
      this.regBookName = true;
      return this.$message.error('图书名称含有非法字符');
    }
    if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(form.alias)) {
      this.regAliasName = true;
      return this.$message.error('工单别名含有非法字符');
    }
  }

  submitCheckForm(form: IOrderForm) {
    if (!form.book_name && this.form?.service_content !== 25) return this.$message.error('请输入图书名称');
    if (!form.alias && this.form?.service_content !== 25) return this.$message.error('请输入工单别名');
    if (!form.subject) return this.$message.error('请选择学科');
    if (!form.stage) return this.$message.error('请选择学段');
    if (!form.customer_id) return this.$message.error('请选择客户');
    if (!form.app_key) return this.$message.error('请选择AppKey');
    if (!form.deadline) return this.$message.error('请输入承诺完成时间');
    if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(form.book_name)) {
      this.regBookName = true;
      return this.$message.error('图书名称含有非法字符');
    }
    if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(form.alias)) {
      this.regAliasName = true;
      return this.$message.error('工单别名含有非法字符');
    }
    // if (this.formInfo?.fileType.includes('zip')) {
    //   if(this.formInfo.category === 'digitization') {
    //     if (this.fileType === 0) {
    //       if (!this.form.material.oss_fbd_zips?.length) return this.$message.error('请上传zip文件');
    //     }
    //   } else {
    //     if (!this.form.material.oss_fbd_zips?.length) return this.$message.error('请上传zip文件');
    //   }
    // }
    // if (!this.form.material.oss_pdf_files?.length) return this.$message.error('请上传pdf文件');
    // 文件检查
    if (this.form.material.type === 0) {
      if (!this.form.material.oss_fbd_zips?.length) return this.$message.error('请上传zip文件');
    } else if (this.form.material.type === 1) {
      if (!this.form.material.oss_word_files?.length) return this.$message.error('请上传word文件');
    } else if (this.form.material.type === 2) {
      if (!this.form.material.oss_images?.length) return this.$message.error('请上传图片文件');
    } else if (this.form.material.type === 3) {
      if (!this.form.material.oss_pdf_files?.length) return this.$message.error('请上传pdf文件');
    } else if (this.form.material.type === 16) {
      if ((this.$children[2] as any)?.files.some((item: any) => item.ZIPinZIPList.length)) return this.$message.error('请移除无效压缩包');
      if (!this.form.material.oss_zip_files?.length) return this.$message.error('请上传ppt压缩包');
    }
    if (this.type === 'editableCourseWare') {
      if (!this.form.material.courseware_sample_files?.length) return this.$message.error('请上传样例课件、制作说明文件');
    }
    if (this.formInfo?.fileType.includes('zip') && this.form.service_content !== 25) {
      if (this.formInfo.category === 'digitization') {
        if (this.fileType === 0) {
          if (!this.form.xdoc_planning?.length) return this.$message.error('请选择要制作的内容');
          const names = this.form.xdoc_planning.map(item => item.name);
          if (Array.from(new Set(names)).length !== this.form.xdoc_planning.length) return this.$message.error('制作内容中有重名项目');
          // 清除开发添加的字段
          for (const i of this.form.xdoc_planning) {
            if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(i.name || '')) {
              return this.$message.error('制作内容含有非法字符');
            }
            if (!i.origin_files!.filter(fbd => fbd.type === 1).length) {
              return this.$message.error('制作内容中未选择正文fbd文件');
            }
            if (!i.compare_pdf!.filter(pdf => pdf.type === 1).length) {
              return this.$message.error('制作内容中未选择正文pdf文件');
            }
            if (i.config.fbd_type === 1) {
              if (!i.origin_files!.filter(fbd => fbd.type === 0).length) {
                return this.$message.error('制作内容中未选择答案fbd文件');
              }
              if (!i.compare_pdf!.filter(pdf => pdf.type === 0).length) {
                return this.$message.error('制作内容中未选择答案pdf文件');
              }
            }
          }
        }
      } else {
        if (!this.form.xdoc_planning?.length) return this.$message.error('请选择要制作的内容');
        const names = this.form.xdoc_planning.map(item => item.name);
        if (Array.from(new Set(names)).length !== this.form.xdoc_planning.length) return this.$message.error('制作内容中有重名项目');
        // 清除开发添加的字段
        for (const i of this.form.xdoc_planning) {
          if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(i.name || '')) {
            return this.$message.error('制作内容含有非法字符');
          }
          if (!i.origin_files!.filter(fbd => fbd.type === 1).length) {
            return this.$message.error('制作内容中未选择正文fbd文件');
          }
          if ((this.formInfo.category !== 'studentToTeacher' && this.form.material.type !== 1) && !i.compare_pdf!.filter(pdf => pdf.type === 1).length) {
            return this.$message.error('制作内容中未选择正文pdf文件');
          }
          if (i.config.fbd_type === 1 && this.form.material.type !== 1) {
            if (!i.origin_files!.filter(fbd => fbd.type === 0).length) {
              return this.$message.error('制作内容中未选择答案fbd文件');
            }
            if (this.formInfo.category !== 'studentToTeacher' && !i.compare_pdf!.filter(pdf => pdf.type === 0).length) {
              return this.$message.error('制作内容中未选择答案pdf文件');
            }
          }
        }
      }
    }
    if ([11, 16].includes(this.form.service_content) && this.form.material.type === 5) {
      if (!this.form.xdoc_planning?.length) return this.$message.error('请选择要制作的内容');
      const names = this.form.xdoc_planning.map(item => item.name);
      if (Array.from(new Set(names)).length !== this.form.xdoc_planning.length) return this.$message.error('制作内容中有重名项目');
      for (const i of this.form.xdoc_planning) {
        if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(i.name || '')) {
          return this.$message.error('制作内容含有非法字符');
        }
        if (!i.origin_files!.filter(word => word.type === 1).length) {
          return this.$message.error('制作内容中未选择 word 文件');
        }
        if (!i.config.style_config_data) {
          return this.$message.error('制作内容中未选择方正样式');
        }
      }
    }
    if (this.form.service_content === 5 && this.form.material.type === 1) {
      if (!this.form.xdoc_planning?.length) return this.$message.error('请选择要制作的内容');
      const names = this.form.xdoc_planning.map(item => item.name);
      if (Array.from(new Set(names)).length !== this.form.xdoc_planning.length) return this.$message.error('制作内容中有重名项目');
      for (const i of this.form.xdoc_planning) {
        if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(i.name || '')) {
          return this.$message.error('制作内容含有非法字符');
        }
        if (!i.origin_files!.filter(fbd => fbd.type === 1).length) {
          return this.$message.error('制作内容中未选择正文fbd文件');
        }
        if (i.config.fbd_type === 1) {
          if (!i.origin_files!.filter(fbd => fbd.type === 0).length) {
            return this.$message.error('制作内容中未选择答案fbd文件');
          }
        }
      }
    }
    if (!this.form.xdoc_planning.length && this.form.service_content !== 18 && this.form.service_content !== 25) return this.$message.error('请确认工单信息是否完全');
  }

  async saveTitleList(titleList: any) {
    // this.$set(this.form.xdoc_planning[0].config, 'stu2tea_title', titleList);
    this.form.xdoc_planning.map((plan: any) => {
      this.$set(plan.config, 'stu2tea_title', titleList);
    });
    await setOrgConfig({
      customer_id: this.organization.id,
      fbd_align_config: {
        titleList
      }
    });
    this.$message.success('保存成功');
  }

  // debounce 防止按钮在下单时被多次点击发出多个请求导致前端报错显示异常（直接跳回主页）
  debounceSubmit = _.debounce(this.submit, 500);
  async submit() {
    if (this.formInfo?.formData && [12, 13, 14, 15].includes(this.formInfo?.formData.service_content)) {
      this.setImageXdocPlanning(this.formInfo?.formData.service_content);
    }
    if (this.submitCheckForm(this.form)) return;
    if (this.formInfo?.formData.service_content === 10) {
      this.form.xdoc_planning.map((plan: any) => {
        if (plan.config?.stu2tea_config) {
          this.$set(plan.config.stu2tea_config, 'grade', this.form.stage);
          this.$set(plan.config.stu2tea_config, 'subject', this.form.subject);
        }
      });
    }

    const formData = _.cloneDeep({ ticket_id: this.order_id, ...this.screenForm(this.form) });
    if (this.form.material.type === 1 && this.form.xdoc_planning.length) {
      this.form.xdoc_planning.map(plan => {
        if (plan.config.fbd_type !== 0) plan.config!.is_word_json_o2o = false;
      });
    }
    // xdoc planning带上 book_type
    if (formData.xdoc_planning.length && formData.alias) {
      formData.xdoc_planning.map((plan: any) => {
        plan.config!.book_type = plan.name.split(formData.alias + '-').join('');
      });
    }
    // 内容数字化 - pdf 筛选框生成物
    if (this.digit_delivery && formData.material.type === 3) {
      formData.xdoc_planning &&
        formData.xdoc_planning.map((projetct: any) => {
          projetct.config.delivery = this.digit_delivery;
        });
    }
    // pdf转word
    if (this.digit_delivery && formData.material.type === 3 && this.name === 'PDF转Word') {
      formData.xdoc_planning &&
        formData.xdoc_planning.map((projetct: any) => {
          projetct.config.delivery = 1;
        });
    }
    // 是否需要附带信息
    if (this.needAttachment) {
      formData.xdoc_planning && formData.xdoc_planning.map((projetct: any) => {
        if (projetct.config.delivery !== 1) {
          projetct.config.delivery = this.form.service_content === 0 ? (
            projetct.config.delivery === 0 ? 6 : projetct.config.delivery
          ) : 5;
        }
      });
    }
    // 是否需要希沃白板文件
    if (this.needSeewo) {
      formData.xdoc_planning &&
        formData.xdoc_planning.map((projetct: any) => {
          projetct.config.need_seewo = true;
        });
    }

    // ppt转希沃配置
    if (this.form.service_content === 25) {
      formData.xdoc_planning.push({
        config: { ppt2seewo_config: { ...this.form.config }, delivery: 20 },
        origin_files: [...this.form.material.oss_zip_files]
      });
      if (!formData.book_name) {
        formData.book_name = formData.material.oss_zip_files?.[0].name.replace('.zip', '');
        formData.alias = formData.material.oss_zip_files?.[0].name.replace('.zip', '');
        formData.xdoc_planning.forEach((item: any) => item.name = formData.book_name);
      }
      delete formData.config;
    } else {
      delete formData.config;
    }

    await this.handleSend(formData);
  }

  buttonLoading = false;
  async handleSend(formData: any) {
    try {
      // const formData = _.cloneDeep({ ticket_id: this.order_id, ...this.screenForm(this.form) });
      this.buttonLoading = true;
      formData.material.content_desc = this.formInfo?.category === 'digitization' ? formData.material.content_desc : formData.material.courseware_sample_desc;
      formData.is_free = this.isFree;
      await editOrder(formData);
      if (!this.reEdit) await submitOrder({ ticket_id: this.order_id });
      else {
        // 暂时不需要重跑了
        // await this.rerunOrderHandle();
        this.$message.success('修改成功');
        await this.$router.push('/order');
        return;
      }
      this.$message.success('下单成功');
      await this.$router.push(`/success?id=${this.order_id}`);
      this.buttonLoading = false;
    } catch (error) {
      this.$message.error(`下单出现异常，原因：${(error as any).message || error}`);
      this.buttonLoading = false;
    }
  }

  async rerunOrderHandle() {
    try {
      await rerunWord2FbdOrder({
        ticket_id: this.form.ticket_id!,
        run_type: 'rerun'
      });
      await rerunOrderStatus({
        ticket_id: this.form.ticket_id!,
      });
    } catch (error) {
      console.log(error);
      throw new Error((error as any).message);
    }
  }

  async back() {
    if (this.order_id) {
      await editOrder({ ticket_id: this.order_id, ...this.screenForm(this.form) });
      this.$router.push('/service');
    } else {
      this.$router.push('/service');
    }
  }

  screenForm(form: any) {
    const keys = Object.keys(form);
    const defaultKeys = Object.keys(this.defaultForm);
    const screenkeys = keys.filter(item => defaultKeys.includes(item));
    const screenForm: any = {};
    screenkeys.forEach(item => {
      screenForm[item] = form[item];
    });
    return screenForm;
  }

  showMore = false;
  fileType = 0;
  handleFileTypeChange(e: number) {
    if (e === 0) {
      this.showMore = true;
    } else {
      this.showMore = false;
    }
    if (e !== 2) {
      this.form.xdoc_planning = [];
    }
    setTimeout(() => {
      this.$set(this.form.material, 'type', e);
      this.fileType = e;
    }, 100);
  }
  handleDefaultXdocPlanning() {
    if (this.form.xdoc_planning.length) {
      return;
    }
    const { type } = this;
    let xdoc_planning: IXdocPlanning | null = null;
    switch (type) {
      case 'screenShotCourseWare':
        xdoc_planning = {
          is_del: false,
          name: this.form.alias,
          origin_files: [],
          config: {
            delivery: 0
          }
        };
        break;
      case 'imgToDocx':
        xdoc_planning = {
          is_del: false,
          name: this.form.alias,
          origin_files: [],
          config: {
            delivery: 1
          }
        };
        break;
      case 'imgToFbd':
        xdoc_planning = {
          is_del: false,
          name: this.form.alias,
          origin_files: [],
          config: {
            delivery: 4
          }
        };
        break;
    }
    // if(this.formInfo!.category === 'digitization') {
    //   if (this.fileType !== 0) { // 内容数字化 fbd
    //     xdoc_planning = {
    //       is_del:false,
    //       name: this.form.alias,
    //       origin_files: [],
    //       config: {
    //         delivery: 3
    //       }
    //     }
    //   }
    // }
    if (xdoc_planning) {
      this.form.xdoc_planning = [xdoc_planning];
    }
  }

  setImageXdocPlanning(service_content: number) {
    const service2delivery = [[12, 10], [13, 11], [14, 12], [15, 13]];
    const xdoc_planning: IXdocPlanning = {
      is_del: false,
      name: this.form.alias,
      origin_files: [],
      config: {
        delivery: service2delivery.find(s2d => s2d[0] === service_content)?.[1] as number
      }
    };
    if ([12, 13, 15].includes(service_content)) {
      xdoc_planning.origin_files?.push({
        name: this.form.material.oss_image_zips[0].name,
        image_zip_name: this.form.material.oss_image_zips[0].name,
        type: 0
      });
    } else if (service_content === 14) {
      const text = this.form.material.oss_image_zips.find(zip => !zip.image_type);
      const answer = this.form.material.oss_image_zips.find(zip => zip.image_type === 'answer');
      xdoc_planning.origin_files?.push({
        name: text?.name as string,
        image_zip_name: text?.name,
        type: 0
      });
      xdoc_planning.origin_files?.push({
        name: answer?.name as string,
        image_zip_name: answer?.name,
        type: 1
      });
    }
    this.form.xdoc_planning = [xdoc_planning];
  }

  async handleSaveForm() {
    await editOrder({ ticket_id: this.order_id, ...this.screenForm(this.form) });
  }
};
