
import JsZip from 'jszip';
import iconv from 'iconv-lite';
// @ts-ignore
import CircleProgress from 'vue-circleprogressbar';
// @ts-ignore
import Oss from 'ali-oss';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { selectFile } from '@/utils/FileApis';
import { uuid } from '@/utils/uuid';
import { getFileMd5, handleUrl } from '../helper';
import { getOssInfo, creatOrder, editOrder, getMaterialFile, creatMaterialFile, creatMaterialFileSymlink, setUploadHistory } from '@/api/placeOrder';
import { IOrderForm, IFileList } from '@/types/placeOrderTypes';
import { Action, Getter } from 'vuex-class';
import { IServiceMap } from '@/business/BusinessFormData';
import OrderContentProcess from './imageContentProcess.vue';

interface ISelectInfo {
  accept: string;
  regText: string[];
  multiple: boolean;
  targetList: 'zipList' | 'pdfList' | 'imgList' | 'wordList';
  type: 'zip' | 'pdf' | 'word';
  zipType?: string;
}

@Component({
  components: {
    CircleProgress,
    OrderContentProcess
  }
})

export default class UploadMaterial extends Vue {
  @Prop() form!: IOrderForm;
  @Prop() screenForm!: Function;
  @Prop() checkForm!: Function;
  @Prop({}) formInfo!: IServiceMap;
  @Prop({}) type!: string;
  @Action('setUploading') setUploading: any;
  @Getter('uploading') uploading: any;
  @Action('setOrderId') setOrderId: any;
  @Getter('order_id') order_id: any;
  @Getter('user') user: any;
  files: IFileList[] = [];
  fbdList: any = {};
  activeNames: string[] = [];
  zipList: IFileList[] = [];
  pdfList: IFileList[] = [];
  pptList: IFileList[] = [];
  imgList: IFileList[] = [];
  wordList: IFileList[] = [];
  checking = false;
  fileType: '' | number = '';

  @Watch('files')
  async onFbdFilesChange() {
    this.zipList = [];
    this.pdfList = [];
    this.imgList = [];
    this.wordList = [];
    this.checking = true;
    for (const item of this.files) {
      if (item.zip) {
        if (this.isPpt2Seewo && this.hasRar7z(item?.zip?.name)?.length) {
          item.status = 'check-success';
          item.checkInfo = '检查通过';
          setTimeout(() => {
            item.status = 'init';
            this.activeNames.push(item.zip!.name);
          }, 1000);
          item.pptList = [{ "name": "新建 PPT 演示文稿.ppt" }]
          if (!item.md5) {
            item.md5 = await getFileMd5(item.zip as File) as string;
          }

          this.zipList.push(item);
        } else if (item.status === 'checking') {
          this.zipList.push(item);
          if ((item.zip as File).size > 1024 * 1024 * 1024) {
            item.status = 'check-fail';
            item.checkInfo = '文件大小超过1G，请拆分后上传';
            return;
          }
          try {
            const zip = new JsZip();
            await zip.loadAsync(item.zip as File, { decodeFileName: (bytes: any) => { return iconv.decode(bytes, 'gbk'); } } as any).then(async (file: any) => {
              const fileList = Object.values(file.files);
              const fbdList = fileList.filter((item: any) => item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'fbd')
                .map(item => {
                  const nameArr = (item as any).name.split('.');
                  nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1].toLowerCase();
                  return { name: nameArr.join('.') };
                }).sort((a: any, b: any) => a.name.localeCompare(b.name));
              item.fbdList = fbdList;
              const pptList = fileList.filter((item: any) => item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'ppt' || item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'pptx')
                .map(item => {
                  const nameArr = (item as any).name.split('.');
                  nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1].toLowerCase();
                  return { name: nameArr.join('.') };
                }).sort((a: any, b: any) => a.name.localeCompare(b.name));
              // 判断压缩包内是否有压缩包
              const ZIPinZIPList = fileList.filter((item: any) => item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'zip' || item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'rar' || item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === '7z')
                .map(item => {
                  const nameArr = (item as any).name.split('.');
                  nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1].toLowerCase();
                  return { name: nameArr.join('.') };
                }).sort((a: any, b: any) => a.name.localeCompare(b.name));

              item.ZIPinZIPList = ZIPinZIPList;
              item.pptList = pptList;
              const imageList = fileList.filter((item: any) => ['jpg', 'png', 'jpeg', 'tif', 'eps'].includes(item.name.split('.')[item.name.split('.').length - 1].toLowerCase()))
                .map(item => {
                  const nameArr = (item as any).name.split('.');
                  nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1].toLowerCase();
                  return { name: nameArr.join('.') };
                });
              item.imageList = imageList;
              if (!item.md5) {
                item.md5 = await getFileMd5(item.zip as File) as string;
              }
              if (item.status === 'checking') {
                // ppt转希沃
                if ([25].includes(this.formInfo.formData.service_content) && !pptList.length) {
                  item.status = 'check-fail';
                  item.checkInfo = '无PPT文件';
                }
                if ([25].includes(this.formInfo.formData.service_content) && ZIPinZIPList.length) {
                  item.status = 'check-fail';
                  item.checkInfo = '压缩包不规范，压缩包内存在嵌套压缩包';
                }
                // E2 是否无FBD文件检测
                if (![12, 13, 14, 15, 25].includes(this.formInfo.formData.service_content) && !fbdList.length) {
                  item.status = 'check-fail';
                  item.checkInfo = '无FBD文件';
                }
                // 学转教 fbd 文件名内不能包含空格
                if ([10].includes(this.formInfo.formData.service_content) && fbdList.some((item: any) => item.name.includes(' ') || item.name.includes('(') || item.name.includes(')') || item.name.includes('（') || item.name.includes('）'))) {
                  item.status = 'check-fail';
                  item.checkInfo = '文件夹或者FBD文件名中不允许包含空格和括号';
                }
                // E3 是否项目包内不含素材文件检测
                if (![10, 12, 13, 14, 15, 25].includes(this.formInfo.formData.service_content) && item.status !== 'check-fail') {
                  const errorNoMaterial = !fileList.some((item: any) => item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'tif' || item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'tiff' || item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'eps');
                  if (errorNoMaterial) {
                    item.status = 'check-warning';
                    item.checkInfo = '项目包内不含素材文件';
                  }
                }
                // W1 警告 没有与FBD文件对应的PRO文件检测
                if (![10, 12, 13, 14, 15, 25].includes(this.formInfo.formData.service_content) && item.status !== 'check-fail') {
                  const proList = fileList.filter((item: any) => item.name.split('.')[item.name.split('.').length - 1].toLowerCase() === 'pro').map(item => {
                    const nameArr = (item as any).name.split('.');
                    nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1].toLowerCase();
                    return nameArr.join('.');
                  });
                  for (let i = 0; i < fbdList.length; i++) {
                    const name = fbdList[i].name.split('.')[0];
                    const hasPro = proList.some((i: any) => {
                      return i.split('.')[0] === name;
                    });
                    if (hasPro) {
                      item.status = 'check-success';
                      item.checkInfo = '检查通过';
                      setTimeout(() => {
                        item.status = 'init';
                        this.activeNames.push(item.zip!.name);
                      }, 1000);
                      break;
                    } else {
                      item.status = 'check-warning';
                      item.checkInfo = '没有与FBD文件对应的PRO文件';
                    }
                  }
                }
                if (item.status !== 'check-fail') {
                  item.status = 'check-success';
                  item.checkInfo = '检查通过';
                  setTimeout(() => {
                    item.status = 'init';
                    this.activeNames.push(item.zip!.name);
                  }, 1000);
                }
              }
            });
          } catch (error) {
            // E1 是否有损坏zip包检测
            item.status = 'check-fail';
            item.checkInfo = 'ZIP文件无法打开，请检查后重新上传';
          }
        } else {
          this.zipList.push(item);
        }
      }
      if (item.pdf) {
        if (item.status === 'checking') {
          this.pdfList.push(item);
          if (!item.md5) {
            item.md5 = await getFileMd5(item.pdf as File) as string;
            item.status = 'init';
          }
        } else {
          this.pdfList.push(item);
        }
      }
      if (item.img) {
        if (item.status === 'checking') {
          this.imgList.push(item);
          if (!item.md5) {
            item.md5 = await getFileMd5(item.img as File) as string;
            item.status = 'init';
          }
        } else {
          this.imgList.push(item);
        }
      }
      if (item.word) {
        if (item.status === 'checking') {
          this.wordList.push(item);
          if (!item.md5) {
            item.md5 = await getFileMd5(item.word as File) as string;
            item.status = 'init';
          }
        } else {
          this.wordList.push(item);
        }
      }
    }
    this.checking = false;
    console.log(this.checking);
  }

  @Watch('form')
  async formChange() {
    await this.init();
  }

  get isDebug() {
    const { query } = this.$route;
    return Object.hasOwnProperty.call(query, 'debug');
  }

  get isPpt2Seewo() {
    return this.formInfo.formData.service_content === 25
  }

  hasRar7z(name: string) {
    if (name.includes('.rar')) return 'rar'
    else if (name.includes('.7z')) return '7z'
    else ''
  }

  async mounted() {
    if (this.formInfo.mode === 'selected') {
      if ([5, 11].includes(this.formInfo?.formData?.material?.type || 0)) {
        this.fileType = 1;
      } else {
        this.fileType = 0;
        this.handleFileTypeChange();
      }
    }
    if (this.form.material.type === 0) {
      this.fileType = 0;
      this.handleFileTypeChange();
    } else if (this.form.material.type === 1) {
      this.fileType = 1;
      this.handleFileTypeChange();
    }
    await this.init();
  }

  init() {
    this.pdfList = this.form.material.oss_pdf_files.map(item => {
      return {
        pdf: { name: item.name },
        status: 'upload-success',
      };
    });
    this.pptList = this.form.material.oss_ppt_files.map(item => {
      return {
        pdf: { name: item.name },
        status: 'upload-success',
      };
    });
    this.wordList = this.form.material.oss_word_files!.map(item => {
      return {
        word: { name: item.name },
        status: 'upload-success',
      };
    });
    this.zipList = this.form.material.oss_fbd_zips.map(item => {
      return {
        zip: { name: item.name },
        fbdList: item.fbd_info,
        status: 'upload-success'
      };
    });
    this.zipList = this.zipList.concat(this.form.material.oss_image_zips.map(item => {
      return {
        zip: { name: item.name },
        imageList: item.image_info,
        zipType: item.image_type,
        status: 'upload-success'
      };
    }));
  }

  getOriginFilesName(file: string) {
    const name = file.split('/')[file.split('/').length - 1];
    const nameArr = name.split('.');
    nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1].toLowerCase();
    return nameArr.join('.');
  }

  sizeFilter(bytesize: number) {
    let i = 0;
    // 当bytesize 大于是1024字节时，开始循环，当循环到第4次时跳出；
    while (Math.abs(bytesize) >= 1024) {
      bytesize = bytesize / 1024;
      i++;
      if (i === 4) break;
    }
    // 将Bytes,KB,MB,GB,TB定义成一维数组；
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const newsize = bytesize.toFixed(2);
    return newsize + ' ' + units[i];
  }
  selectAFile(selectInfo: ISelectInfo) {
    selectFile({
      accept: selectInfo.accept,
      multiple: selectInfo.multiple
    }, async (files) => {
      if ([11, 16].includes(this.formInfo.formData.service_content) && selectInfo.type === 'word') {
        files = files.filter(f => {
          return f.size < 30 * 1024 * 1024;
        });
        if (!files.length) {
          this.$message.warning('请上传小于 30M 的 WORD 文件！');
          return;
        }
      }
      if (files.some(file => !/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(file.name))) {
        this.$message.warning('存在非法字符\\ / : * ? " < > | \' ');
        return;
      }
      files = files.filter(f => {
        return selectInfo.regText.some(i => new RegExp(`.${i}$`, 'i').test(f.name));
      });
      if (!files.length) {
        this.$message.warning(`请上传以${selectInfo.accept}后缀格式文件！`);
        return;
      }
      files = files.filter(f => {
        return !this.files.filter(item => item[selectInfo.type] && item[selectInfo.type]!.name === f.name).length && !this[selectInfo.targetList].filter(item => item[selectInfo.type] && f.name === item[selectInfo.type]!.name).length;
      });
      if (this.fileType === 3 && this.pdfList.length + files.length > 2) {
        this.$message.warning('最多上传两本 pdf 文件(正文+答案)！');
        return;
      }
      const self = this;
      if (files.length && files.reduce((acc, cur) => acc + cur.size, 0) > 1024 * 1024 * 1024) {
        this.$message.warning('上传文件总大小不能超过 1G');
      }
      files.forEach((file) => {
        const item = {
          id: '',
          zip: null,
          pdf: null,
          img: null,
          status: 'checking',
          checkInfo: '',
          uploadInfo: '',
          progress: 0,
        } as any;
        const uid = uuid();
        item.id = uid;
        if (self.fileType === 3) {
          if (files.some(item => item.type === 'body') || self.files.filter(item => item.pdf).some(item => item.type === 'body')) item.type = 'answer';
          else item.type = 'body';
        }
        if (selectInfo.multiple) {
          this.files.push(item);
        } else {
          this.files = [item];
        }
        // 排序
        if (selectInfo.type === 'zip' && selectInfo.zipType) {
          item.zipType = selectInfo.zipType;
        }
        item[selectInfo.type] = file;
        item.status = 'checking';
      });
    });
  }
  select() {
    this.selectAFile({
      accept: '.zip',
      multiple: true,
      regText: ['zip'],
      type: 'zip',
      targetList: 'zipList',
    });
  }
  selectZip(multiple?: boolean, zipType?: string) {
    this.selectAFile({
      accept: this.isPpt2Seewo ? '.zip, .rar, .7z' : '.zip',
      multiple: typeof multiple !== 'undefined' ? multiple : true,
      regText: this.isPpt2Seewo ? ['.zip', '.rar', '.7z'] : ['zip'],
      type: 'zip',
      targetList: 'zipList',
      zipType: zipType
    });
    // selectFile({
    //   accept: '.zip',
    //   multiple: true
    // }, async (files) => {
    //   if (files.some(file => !/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(file.name))) {
    //     this.$message.warning('存在非法字符\\ / : * ? " < > | \' ');
    //     return;
    //   }
    //   files = files.filter(f => /\.zip$/i.test(f.name));
    //   if (!files.length) {
    //     this.$message.warning('请上传以.zip后缀格式文件！');
    //     return;
    //   }
    //   files = files.filter(f => {
    //     return !this.files.filter(item => item.zip && item.zip.name === f.name).length && !this.zipList.filter(item => item.zip && f.name === item.zip.name).length;
    //   });
    //   files.forEach((file) => {
    //     const item = {
    //       id: '',
    //       zip: null,
    //       pdf: null,
    //       img: null,
    //       status: 'checking',
    //       checkInfo: '',
    //       uploadInfo: '',
    //       progress: 0,
    //     } as any;
    //     const uid = uuid();
    //     item.id = uid;
    //     this.files.push(item);
    //     item.zip = file;
    //     item.status = 'checking';
    //   });
    // });
  }

  selectPdf(type: 'digitization') {
    const isMultiple = !type || this.fileType === 3;
    this.selectAFile({
      accept: '.pdf',
      multiple: isMultiple,
      regText: ['pdf'],
      type: 'pdf',
      targetList: 'pdfList',
    });
  }

  selectWord() {
    this.selectAFile({
      accept: [11, 16].includes(this.formInfo.formData.service_content) ? '.doc, .docx' : '.docx',
      multiple: ![11, 16, 18].includes(this.formInfo.formData.service_content),
      regText: ['docx'],
      type: 'word',
      targetList: 'wordList',
    });
  }

  async startUploadZip() {
    if (!this.form.subject) return this.$message.error('请选择学科');
    if (!this.form.stage) return this.$message.error('请选择学段');
    let order_id = this.order_id;
    if (!order_id) {
      if (this.checkForm(this.form)) return;
      const res = await creatOrder({
        ...this.form
      });
      order_id = res.ticket_id;
      (this as any).$updateQuery({
        id: order_id,
      });
      this.setOrderId(order_id);
    }
    if (this.uploading) return;
    this.setUploading(true);
    const auth = await getOssInfo({ ticket_id: order_id, path_alias: 'file' });
    const { access_key_id, access_key_secret_id, bucket, region, key } = auth;
    let zipList;
    if (this.formInfo.category === 'pptUpload') {
      zipList = this.files.filter(item => item.zip && !this.form.material.oss_zip_files.filter((zip: any) => zip!.name === item.zip!.name).length);
    } else {
      zipList = this.files.filter(item => item.zip && !this.form.material.oss_fbd_zips.filter(zip => zip!.name === item.zip!.name).length);
    }
    console.log(zipList);
    if (!zipList.length) {
      this.$message.warning('请先选择一个zip文件');
      return;
    }
    zipList = zipList.filter(item => !item.ZIPinZIPList?.length)
    for (const item of zipList) {
      try {
        item.status = 'uploadding';
        item.progress = 0;
        const material = await getMaterialFile({
          md5: item.md5!
        });
        const { path } = material;
        if (path) {
          if (this.form) {
            const symlinkData = await creatMaterialFileSymlink({
              ticket_id: this.order_id,
              origin_path: path,
              name: item.zip!.name,
              path_alias: 'content_zip_type'
            });
            if ([12, 13, 14, 15].includes(this.formInfo.formData.service_content)) {
              if (!this.form.material.oss_image_zips) this.form.material.oss_image_zips = [];
              this.form.material.oss_image_zips.push({ path: handleUrl(symlinkData.symlink_path), is_del: false, extension: 'zip', name: item.zip!.name, md5: item.md5, image_info: item.imageList, image_type: item.zipType });
            } else if ([25].includes(this.formInfo.formData.service_content)) {
              if (!this.form.material.oss_zip_files) this.form.material.oss_zip_files = [];
              if (this.hasRar7z(item.zip!.name)?.length) {
                this.form.material.oss_zip_files.push({ path: handleUrl(symlinkData.symlink_path), is_del: false, extension: this.hasRar7z(item.zip!.name) as string, name: item.zip!.name, md5: item.md5 });
              } else {
                this.form.material.oss_zip_files.push({ path: handleUrl(symlinkData.symlink_path), is_del: false, extension: 'zip', name: item.zip!.name, md5: item.md5 });
              }
            } else {
              if (!this.form.material.oss_fbd_zips) this.form.material.oss_fbd_zips = [];
              this.form.material.oss_fbd_zips.push({ path: handleUrl(symlinkData.symlink_path), is_del: false, extension: 'zip', name: item.zip!.name, fbd_info: item.fbdList, md5: item.md5 });
            }
            item.progress = 100;
            item.status = 'upload-success';
            continue;
          }
        }
        const client = new Oss({ region, accessKeyId: access_key_id, accessKeySecret: access_key_secret_id, bucket });
        await client.multipartUpload(`${key}/${item.md5}.zip`, item.zip, {
          parallel: 5,
          partSize: 1024 * 1024,
          timeout: 4000000,
          progress: function (p: number) {
            item.progress = +(p * 100).toFixed(2);
          }
        });
        await creatMaterialFile({
          md5: item.md5!,
          name: item.zip!.name,
          extension: 'zip',
          path: `${key}/${item.md5}.zip`
        });
        const symlinkData = await creatMaterialFileSymlink({
          ticket_id: this.order_id,
          origin_path: `${key}/${item.md5}.zip`,
          name: item.zip!.name,
          path_alias: 'content_zip_type'
        });
        if (this.form) {
          if ([12, 13, 14, 15].includes(this.formInfo.formData.service_content)) {
            if (!this.form.material.oss_image_zips) this.form.material.oss_image_zips = [];
            this.form.material.oss_image_zips.push({ path: handleUrl(symlinkData.symlink_path), is_del: false, extension: 'zip', name: item.zip!.name, md5: item.md5, image_info: item.imageList, image_type: item.zipType });
          } else {
            if (!this.form.material.oss_fbd_zips) this.form.material.oss_fbd_zips = [];
            this.form.material.oss_fbd_zips.push({ path: handleUrl(symlinkData.symlink_path), is_del: false, extension: 'zip', name: item.zip!.name, fbd_info: item.fbdList, md5: item.md5 });
          }
        }
        item.status = 'upload-success';
      } catch (e) {
        await setUploadHistory({
          user_id: this.user.userId,
          username: this.user.nickname,
          ticket_id: this.order_id,
          type: 9999,
          ext: {
            data: `${item.zip!.name} 上传失败！请检查该文件的MD5值是否存在${item.md5}`
          }
        });
        item.status = 'upload-fail';
        item.uploadInfo = e as any;
        item.progress = 0;
      }
    }
    await editOrder({ ticket_id: this.order_id, ...this.screenForm(this.form) });
    this.setUploading(false);
  }

  async startUploadPdf() {
    let order_id = this.order_id;
    if (!order_id) {
      if (this.checkForm(this.form)) return;
      const res = await creatOrder({
        ...this.form
      });
      order_id = res.ticket_id;
      (this as any).$updateQuery({
        id: order_id,
      });
      this.setOrderId(order_id);
    }
    if (this.uploading) return;
    this.setUploading(true);
    const auth = await getOssInfo({ ticket_id: this.order_id, path_alias: 'file' });
    const { access_key_id, access_key_secret_id, bucket, region, key } = auth;
    const pdfList = this.files.filter(item => item.pdf && !((this.form.material.oss_pdf_files!).filter(pdf => pdf!.name === item.pdf!.name)).length);
    if (!pdfList.length) {
      this.$message.warning('请先选择一个PDF文件');
      return;
    }
    for (const item of pdfList) {
      try {
        item.status = 'uploadding';
        item.progress = 0;
        const material = await getMaterialFile({
          md5: item.md5!
        });
        const { path } = material;
        if (path) {
          if (this.form) {
            if (!this.form.material.oss_pdf_files) this.form.material.oss_pdf_files = [];
            const symlinkData = await creatMaterialFileSymlink({
              ticket_id: this.order_id,
              origin_path: path,
              name: item.pdf!.name,
              path_alias: this.form.material.type === 3 ? 'content_pdf' : 'content_compare_pdf'
            });
            this.form.material.oss_pdf_files.push({ path: symlinkData.symlink_path, is_del: false, extension: 'pdf', name: item.pdf!.name, md5: item.md5, type: item.type });
            item.progress = 100;
            item.status = 'upload-success';
            // if (!this.formInfo?.fileType.includes('zip') || this.fileType !== 0) {
            //   this.form.xdoc_planning[0].name = this.form.alias;
            //   this.form.xdoc_planning[0].origin_files!.push({
            //     index: this.form.material.oss_pdf_files.length,
            //     name: item.pdf!.name,
            //   });
            // }
            continue;
          }
        }
        const client = new Oss({ region, accessKeyId: access_key_id, accessKeySecret: access_key_secret_id, bucket });
        await client.multipartUpload(`${key}/${item.md5}.pdf`, item.pdf, {
          parallel: 5,
          partSize: 1024 * 1024,
          timeout: 4000000,
          progress: function (p: number) {
            item.progress = +(p * 100).toFixed(2);
          }
        });
        await creatMaterialFile({
          md5: item.md5!,
          name: item.pdf!.name,
          extension: 'pdf',
          path: `${key}/${item.md5}.pdf`
        });
        const symlinkData = await creatMaterialFileSymlink({
          ticket_id: this.order_id,
          origin_path: `${key}/${item.md5}.pdf`,
          name: item.pdf!.name,
          path_alias: this.form.material.type === 3 ? 'content_pdf' : 'content_compare_pdf'
        });
        if (this.form) {
          if (!this.form.material.oss_pdf_files) this.form.material.oss_pdf_files = [];
          this.form.material.oss_pdf_files.push({ path: symlinkData.symlink_path, is_del: false, extension: 'pdf', name: item.pdf!.name, md5: item.md5 });
        }
        item.status = 'upload-success';
        // if (!this.formInfo?.fileType.includes('zip') || this.fileType !== 0) {
        //   this.form.xdoc_planning[0].name = this.form.alias;
        //   this.form.xdoc_planning[0].origin_files!.push({
        //     index: this.form.material.oss_pdf_files.length,
        //     name: item.pdf!.name,
        //   });
        // }
      } catch (e) {
        await setUploadHistory({
          user_id: this.user.userId,
          username: this.user.nickname,
          ticket_id: this.order_id,
          type: 9999,
          ext: {
            data: `${item.pdf!.name} 上传失败！请检查该文件的MD5值是否存在${item.md5}`
          }
        });
        item.status = 'upload-fail';
        console.log(e);
        item.uploadInfo = e as any;
        item.progress = 0;
      }
    }
    this.$nextTick(async () => {
      await editOrder({ ticket_id: this.order_id, ...this.screenForm(this.form) });
      this.setUploading(false);
    });
  }

  async startUploadWord() {
    try {
      let order_id = this.order_id;
      if (!order_id) {
        if (this.checkForm(this.form)) return;
        const res = await creatOrder({
          ...this.form
        });
        order_id = res.ticket_id;
        (this as any).$updateQuery({
          id: order_id,
        });
        this.setOrderId(order_id);
      }
      if (this.uploading) return;
      this.setUploading(true);
      const auth = await getOssInfo({
        ticket_id: this.order_id,
        path_alias: 'file'
      });
      const {
        access_key_id,
        access_key_secret_id,
        bucket,
        region,
        key
      } = auth;
      const wordListPre = this.files.filter(item => item.word);
      if (!wordListPre.length) {
        this.$message.warning('请先选择一个Word文件');
        return;
      }
      const wordList = this.files.filter(item => item.word && !this.form.material.oss_word_files!.filter(word => word!.name === item.word!.name).length);
      for (const item of wordList) {
        try {
          item.status = 'uploadding';
          item.progress = 0;
          const material = await getMaterialFile({
            md5: item.md5!
          });
          const { path } = material;
          if (path) {
            if (this.form) {
              if (!this.form.material.oss_word_files) this.form.material.oss_word_files = [];
              const symlinkData = await creatMaterialFileSymlink({
                ticket_id: this.order_id,
                origin_path: path,
                name: handleUrl(item.word!.name),
                path_alias: 'content_word'
              });
              this.form.material.oss_word_files.push({
                path: symlinkData.symlink_path,
                is_del: false,
                extension: 'docx',
                name: item.word!.name,
                md5: item.md5
              });
              item.progress = 100;
              item.status = 'upload-success';
              // if (!this.formInfo?.fileType.includes('zip') || this.fileType !== 0) {
              //   this.form.xdoc_planning[0].name = this.form.alias;
              //   this.form.xdoc_planning[0].origin_files!.push({
              //     index: this.form.material.oss_word_files.length,
              //     name: item.word!.name,
              //   });
              // }
              continue;
            }
          }
          const client = new Oss({
            region,
            accessKeyId: access_key_id,
            accessKeySecret: access_key_secret_id,
            bucket
          });
          await client.multipartUpload(`${key}/${item.md5}.docx`, item.word, {
            parallel: 5,
            partSize: 1024 * 1024,
            timeout: 4000000,
            progress: function (p: number) {
              item.progress = +(p * 100).toFixed(2);
            }
          });
          await creatMaterialFile({
            md5: item.md5!,
            name: item.word!.name,
            extension: 'docx',
            path: `${key}/${item.md5}.docx`
          });
          const symlinkData = await creatMaterialFileSymlink({
            ticket_id: this.order_id,
            origin_path: `${key}/${item.md5}.docx`,
            name: handleUrl(item.word!.name),
            path_alias: 'content_word'
          });
          if (this.form) {
            if (!this.form.material.oss_word_files) this.form.material.oss_word_files = [];
            this.form.material.oss_word_files.push({
              path: symlinkData.symlink_path,
              is_del: false,
              extension: 'docx',
              name: item.word!.name,
              md5: item.md5
            });
          }
          item.status = 'upload-success';
          // if (!this.formInfo?.fileType.includes('zip') || this.fileType !== 0) {
          //   this.form.xdoc_planning[0].name = this.form.alias;
          //   this.form.xdoc_planning[0].origin_files!.push({
          //     index: this.form.material.oss_word_files!.length,
          //     name: item.pdf!.name,
          //   });
          // }
        } catch (e) {
          await setUploadHistory({
            user_id: this.user.userId,
            username: this.user.nickname,
            ticket_id: this.order_id,
            type: 9999,
            ext: {
              data: `${item.word!.name} 上传失败！请检查该文件的MD5值是否存在${item.md5}`
            }
          });
          item.status = 'upload-fail';
          console.log(e);
          item.uploadInfo = e as any;
          item.progress = 0;
        }
      }
      if ([11, 16, 18].includes(this.formInfo.formData.service_content)) {
        let projects = this.form.xdoc_planning || [];
        if (projects.length) {
          projects[0].origin_files = [{
            index: 0,
            type: 1,
            name: wordList[0].name || wordList[0].word?.name || '',
            selected: false
          }];
        } else {
          projects = [{
            name: this.form.alias + '-图书',
            config: {
              fbd_type: 0,
              delivery: this.formInfo.formData.service_content === 18 ? 15 : 9
            },
            origin_files: [{
              index: 0,
              type: 1,
              name: wordList[0].name || wordList[0].word?.name || '',
              selected: false
            }],
            compare_pdf: []
          }];
        }
        this.$set(this.form, 'xdoc_planning', projects);
      }
      await editOrder({ ticket_id: this.order_id, ...this.screenForm(this.form) });
      this.setUploading(false);
    } catch (e) {
      console.log(e);
      this.setUploading(false);
    }
  }

  togglePdfType() {
    this.pdfList.map(item => {
      item.type === 'body' ? item.type = 'answer' : item.type = 'body';
    });
    this.$message.success('切换 PDF 正文答案成功!');
  }

  removePdfFile(name: string) {
    const pdfIndex = this.files.findIndex((item) => item.pdf && item.pdf.name === name);
    const ListIndex = this.pdfList.findIndex((item) => item.pdf && item.pdf.name === name);
    this.files.splice(pdfIndex, 1);
    this.pdfList.splice(ListIndex, 1);
    if (this.form && this.form.material.oss_pdf_files && this.form.material.oss_pdf_files.filter(item => item.name === name).length) {
      this.form.material.oss_pdf_files.splice(ListIndex, 1);
    }
  }

  removeWordFile(name: string) {
    const wordIndex = this.files.findIndex((item) => item.word && item.word.name === name);
    const ListIndex = this.wordList.findIndex((item) => item.word && item.word.name === name);
    this.files.splice(wordIndex, 1);
    this.wordList.splice(ListIndex, 1);
    if (this.form && this.form.material.oss_word_files && this.form.material.oss_word_files.filter(item => item.name === name).length) {
      this.form.material.oss_word_files.splice(ListIndex, 1);
    }
  }

  removeZipFile(name: string) {
    const zipIndex = this.files.findIndex((item) => item.zip && item.zip.name === name);
    const ListIndex = this.zipList.findIndex((item) => item.zip && item.zip.name === name);
    this.files.splice(zipIndex, 1);
    this.zipList.splice(ListIndex, 1);
    delete this.fbdList[name];
    if (this.form.material.oss_fbd_zips.filter(item => item.name === name).length) {
      this.form.material.oss_fbd_zips.splice(ListIndex, 1);
    }
  }

  downloadZip(name: string) {
    if (this.form && this.form.material && this.form.material.oss_fbd_zips) {
      const zip = this.form.material.oss_fbd_zips.find(item => item.name === name);
      const a = document.createElement('a');
      let path = '';
      if (zip!.path?.includes('+')) {
        // const arr = zip!.path!.split('/');
        // arr[arr.length - 1] = encodeURIComponent(arr[arr.length - 1]);
        // path = arr.join('/');
        path = zip!.path!.replaceAll('+', '%2B');
      } else {
        path = zip!.path!;
      }
      a.href = `https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/${path}`;
      a.click();
    }
  }

  downloadPdf(name: string) {
    if (this.form && this.form.material && this.form.material.oss_pdf_files) {
      const pdf = this.form.material.oss_pdf_files.find(item => item.name === name);
      const a = document.createElement('a');
      let path = '';
      if (pdf!.path?.includes('+')) {
        // const arr = pdf!.path!.split('/');
        // arr[arr.length - 1] = encodeURIComponent(arr[arr.length - 1]);
        // path = arr.join('/');
        path = pdf!.path!.replaceAll('+', '%2B');
      } else {
        path = pdf!.path!;
      }
      a.href = `https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/${path}`;
      a.click();
    }
  }
  handleFileTypeChange() {
    this.$emit('type-change', this.fileType);
  }
};
